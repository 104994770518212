import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Dashboard from '../../pages/Unauth-Pages/Dashboard/Dashboard';
import Header from '../../sidbar-header-components/Header/Header';
import Sidenav_bar from '../../sidbar-header-components/Sidenav-bar/Sidenav_bar';
import Order_List from '../../pages/Unauth-Pages/Order-List/Order-List';
import Order_Now from '../../pages/Unauth-Pages/Order-Now/Order-Now';
import Wallet from '../../pages/Unauth-Pages/Wallet/Wallet';
import Manage_Address from '../../pages/Unauth-Pages/Manage-Address/Manage-Address';
import { MyProfile } from '../../pages/Unauth-Pages/My-Profile/My-Profile';
import { AddCard } from '../../pages/Unauth-Pages/Cards/AddCard/AddCard';
import { ManageCards } from '../../pages/Unauth-Pages/Cards/ManageCards/ManageCards';
import Privacy_Policy from '../../pages/Auth-Pages/privacy-policy/privacy-policy';
import Terms_and_Condition from '../../pages/Auth-Pages/Terms-and-Condition/Terms-and-Condition';
import FAQ from '../../pages/Auth-Pages/FAQ/FAQ';


const UnAuthRoutes = () => {


    return (
        <>
            <Sidenav_bar />
            <section className="home-section">

                <div>
                    <Header />
                </div>

                <div className='main_section_holder_div'>
                    <Routes>
                        <Route path='/' element={<Dashboard />} />
                        <Route path='/My-Profile' element={<MyProfile />} />
                        <Route path='/order-list' element={<Order_List />} />
                        <Route path='/order-now' element={<Order_Now />} />
                        <Route path='/manage-address' element={<Manage_Address />} />
                        <Route path='/Manage-Cards' element={<ManageCards />} />
                        <Route path='/Add-Card' element={<AddCard />} />
                        <Route path='/Digicash-Topup' element={<Wallet />} />
                        <Route path='/FAQ' element={<FAQ />} />

                        <Route path='/Privacy-Policy' element={<Privacy_Policy />} />
                        <Route path='/Terms-and-Condition' element={<Terms_and_Condition />} />
                    </Routes>
                </div>

            </section>
        </>
    )
}

export default UnAuthRoutes