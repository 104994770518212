import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { FormControl, Input, Tabs } from "@mui/joy";
import { Link, useNavigate } from "react-router-dom";

export const AddCard = () => {
  const navigate = useNavigate();

  const handleAddCard = () => {
    navigate(-1);
  };

  return (
    <>
      <div className='main_order_list_div'>

        <div className='main_order_list_tabs_section_div'>
          <div> <Link to='/My-Profile'>My Profile</Link></div>
          <div> <Link to='/order-list'>Order list</Link></div>
          <div> <Link to='/manage-address'>Manage Address</Link></div>
          {/* <div className='order_list_tab_active'> <Link to='/Manage-Cards'>Manage Cards</Link></div> */}
          <div> <Link to='/Digicash-Topup'>Digicash Topup</Link></div>
        </div>

        <Stack>
          <Stack>
            <Tabs
              variant="outlined"
              sx={{
                borderRadius: "1rem",
                boxShadow: " 0 3px 10px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <Typography variant="h4" fontWeight="bold" sx={{ my: 2, mx: 2, fontSize: { xs: 20, sm: 30 } }}>
                Add Credit/Debit Card
              </Typography>
              <Divider />
              <Stack sx={{ flexGrow: 1, p: 3 }}>
                <form>
                  <Stack spacing={3}>
                    <FormControl sx={{ width: { xs: "100%", sm: "600px" }, borderRadius: "9px" }}>
                      <Input size="lg" variant="soft" placeholder="Card Number" />
                    </FormControl>
                    <Stack direction={{ xs: "column", sm: "row" }} columnGap={5} alignItems={{ xs: "left", sm: "center" }}>
                      <Typography>Expiry Month</Typography>
                      <Stack direction="row" columnGap={3} alignItems="center">
                        <FormControl sx={{ width: "100px", borderRadius: "9px" }}>
                          <Input size="lg" variant="soft" placeholder="MM" />
                        </FormControl>
                        <FormControl sx={{ width: "100px", borderRadius: "9px" }}>
                          <Input size="lg" variant="soft" placeholder="YY" />
                        </FormControl>
                      </Stack>
                    </Stack>
                    <FormControl>
                      <Input
                        size="lg"
                        variant="soft"
                        sx={{ width: { xs: "100%", sm: "600px" }, borderRadius: "9px" }}
                        placeholder="Security Code"
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        size="lg"
                        variant="soft"
                        sx={{ width: { xs: "100%", sm: "600px" }, borderRadius: "9px" }}
                        placeholder="Name on Card"
                      />
                    </FormControl>
                  </Stack>
                </form>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Button
                  onClick={handleAddCard}
                  variant="contained"
                  sx={{
                    width: "500px",
                    borderRadius: "15px",
                    fontWeight: "bold",
                    fontSize: { xs: 15, sm: 20 },
                  }}
                >
                  Add
                </Button>
              </Box>
            </Tabs>
          </Stack>
        </Stack>

      </div>
    </>
  );
};
